import React from 'react'
import styled, { keyframes } from 'styled-components'
import Img from 'gatsby-image'
import { Link, navigate } from 'gatsby'

import { Button } from './buttons'
import { H2, P } from './text'
import { media } from '../../styles/util'

const wiggle = keyframes`
  0% { transform: rotate(0deg); }
  30% { transform: rotate(2deg) scale(1.02); }
  70% { transform: rotate(-2deg) scale(1.02); }
  100% { transform: rotate(0deg); }
`

const Card = styled('div')<{ isBundles: boolean }>`
  width: 100%;
  margin: 0 auto;
  display: grid;
  place-items: center;
  text-align: center;
  grid-row-gap: 12px;

  & h2 {
    white-space: pre;
    line-height: 1.3;
  }

  & img:hover {
    animation: ${wiggle} 250ms;
  }

  ${media.mobile} {
    grid-template-rows: ${props => (props.isBundles ? 245 : 167)}px;
    & img {
      max-width: ${props => (props.isBundles ? 'none' : 200)}px;
      margin: 0 auto;
    }
    & h2 {
      font-size: ${props => (props.isBundles ? 25 : 16)}px;
    }
    & p {
      max-width: 285px;
    }
  }
  ${media.tablet} {
    grid-template-rows: ${props => (props.isBundles ? 365 : 323)}px;

    & h2 {
      font-size: 25px;
    }
    & img {
      max-width: none;
      padding: 20px 10px;
    }
  }
`

const ButtonWrapper = styled.div`
  padding-top: 10px;

  & button {
    background-color: ${props => props.theme.colors.purple};
    color: #fff;

    &:hover {
      background-color: transparent;
      color: ${props => props.theme.colors.purple};
    }
  }
`

// const PriceText = styled(H2)<{ isBundles: boolean }>`
//   font-size: ${props => (props.isBundles ? '25px' : '16px')} !important;
//   line-height: 1 !important;
// `

// const StruckPrice = styled(H2)`
//   text-decoration: line-through;
//   padding-left: 10px;
//   color: ${props => props.theme.colors.lightPurple};
//   font-size: 25px !important;
//   line-height: 1 !important;
// `

interface ProductDetailInterface {
  productKey: string
  quantityKey: string
  price: number
  variantId: string
}

interface ProductGroupInterface {
  single: ProductDetailInterface
  [key: string]: ProductDetailInterface
}

interface JsonNodeInterface {
  images: any
  sku: string
  cardCopy: string
  slug: string
  title: string
}

interface ProductInterface {
  subscription: ProductGroupInterface
  oneTime: ProductGroupInterface
  jsonData: JsonNodeInterface
}

interface PropsInterface {
  product: ProductInterface
  isBundles: boolean
}

export default (props: PropsInterface) => {
  const { product, isBundles } = props
  const image = product.jsonData.images.bottles.single.childImageSharp.fluid
  const { title, slug, cardCopy } = product.jsonData

  return (
    <Card isBundles={isBundles}>
      <Link to={`/${slug}`} style={{ width: '100%', height: '100%' }}>
        <Img
          fluid={image}
          alt={title}
          style={{ width: '100%', height: '100%' }}
          imgStyle={{ objectFit: 'scale-down' }}
        />
      </Link>
      <Link to={`/${slug}`}>
        <H2>{title}</H2>
      </Link>
      {isBundles && <P>{cardCopy}</P>}
      <ButtonWrapper>
        <Button onClick={() => navigate(`/${slug}`)}>
          <H2>Shop Now</H2>
        </Button>
      </ButtonWrapper>
    </Card>
  )
}
