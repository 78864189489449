import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import ProductCard from '../shared/ProductCard'
import { media } from '../../styles/util'

const Container = styled.div`
  position: relative;

  width: 100%;
  min-height: 500px;
  display: grid;
  place-items: center;
  overflow: visible;

  ${media.mobile} {
    padding: 60px 25px 150px 25px;
  }
  ${media.tablet} {
    padding: 60px 25px 200px 25px;
  }
  ${media.desktop} {
    padding: 60px 200px 300px 200px;
  }
`

const Grid = styled('div')<{ isBundles: boolean }>`
  width: 100%;
  margin: 0 auto;
  display: grid;

  ${media.mobile} {
    grid-template-columns: repeat(${props => (props.isBundles ? 1 : 2)}, 1fr);
    grid-gap: 40px 0px;
  }
  ${media.tablet} {
    max-width: 600px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${props => (props.isBundles ? '110px 100px' : ' 110px 0')};
  }
  ${media.desktop} {
    max-width: 850px;
  }
`

const AbsoluteImgWrapper = styled.div`
  transform: translate(0, -50%);
  position: absolute;
  z-index: -1;
  max-width: 280px;
`

const AshwagandhaBlob = styled(AbsoluteImgWrapper)`
  ${media.mobile} {
    width: 25%;
    top: 2%;
    left: -4%;
  }
  ${media.tablet} {
    width: 22%;
    left: 0;
    top: 25%;
  }
  ${media.desktop} {
    width: 25%;
    top: 33%;
  }
`

const AcvBlob = styled(AbsoluteImgWrapper)`
  ${media.mobile} {
    width: 24%;
    top: -5%;
    left: auto;
    right: -3%;
  }
  ${media.tablet} {
    width: 18%;
    top: 9%;
    left: auto;
    right: 0;
  }
  ${media.desktop} {
    width: 20%;
  }
`

const ElderberryBlob = styled(AbsoluteImgWrapper)`
  ${media.mobile} {
    width: 45%;
    max-width: 200px;
    top: 88%;
    left: -30px;
  }
  ${media.tablet} {
    max-width: 250px;
  }
  ${media.tablet} {
    width: 32%;
    max-width: 450px;
    top: 74%;
    left: -20px;
  }
`

const TurmericBlob = styled(AbsoluteImgWrapper)`
  ${media.mobile} {
    width: 25%;
    top: 89%;
    left: auto;
    right: 0;
  }
  ${media.tablet} {
    width: 20%;
  }
  ${media.tablet} {
    width: 22%;
    top: 56%;
    left: auto;
    right: 0;
  }
`

interface ProductDetailInterface {
  productKey: string
  quantityKey: string
  price: number
  variantId: string
}

interface ProductGroupInterface {
  single: ProductDetailInterface
  [key: string]: ProductDetailInterface
}

interface JsonNodeInterface {
  images: any
  sku: string
  cardCopy: string
  slug: string
  title: string
}

interface ProductInterface {
  subscription: ProductGroupInterface
  oneTime: ProductGroupInterface
  jsonData: JsonNodeInterface
}

interface PropsInterface {
  products: ProductInterface[]
  isBundles: boolean
}

const query = graphql`
  query {
    ashwagandhaBlob: file(relativePath: { regex: "/library/ashwagandha-blob.png/" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    acvBlob: file(relativePath: { regex: "/library/acv-blob.png/" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    elderberryBlob: file(relativePath: { regex: "/library/elderberry-blob.png/" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    turmericBlob: file(relativePath: { regex: "/library/turmeric-blob.png/" }) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default ({ products, isBundles }: PropsInterface) => {
  const { ashwagandhaBlob, acvBlob, elderberryBlob, turmericBlob } = useStaticQuery(query)

  return (
    <Container>
      {!isBundles && (
        <>
          <AshwagandhaBlob>
            <Img
              fluid={ashwagandhaBlob.childImageSharp.fluid}
              aria-hidden
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </AshwagandhaBlob>
          <AcvBlob>
            <Img
              fluid={acvBlob.childImageSharp.fluid}
              aria-hidden
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </AcvBlob>
          <ElderberryBlob>
            <Img
              fluid={elderberryBlob.childImageSharp.fluid}
              aria-hidden
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </ElderberryBlob>
          <TurmericBlob>
            <Img
              fluid={turmericBlob.childImageSharp.fluid}
              aria-hidden
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </TurmericBlob>
        </>
      )}
      <Grid isBundles={isBundles}>
        {products.map((product: ProductInterface) => (
          <ProductCard product={product} key={product?.jsonData?.sku} isBundles={isBundles} />
        ))}
      </Grid>
    </Container>
  )
}
