import * as React from 'react'
import { PageProps } from 'gatsby'

import Layout from '../../layouts'
import ProductsLibrary from '../../components/ProductsLibrary'
import SEO from '../../components/shared/SEO'

const ProductsPage = (props: PageProps) => {
  const { location } = props
  return (
    <>
      <SEO
        title="All Products"
        description="Superfood Grummies are currently available in: Ashwagandha, Elderberry, Apple Cider Vinegar, and Turmeric."
        keywords={[
          'superfood vitamins',
          'gummy vitamins',
          'health gummies',
          'superfoods',
          'adult gummies',
          'adult vitamins',
        ]}
      />
      <Layout location={location}>
        <ProductsLibrary />
      </Layout>
    </>
  )
}

export default ProductsPage
