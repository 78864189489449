import React, { useContext, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { useLockBodyScroll } from 'react-use'
import { gsap } from 'gsap'

import ProductGrid from './ProductGrid'
import ImageCarousel from '../shared/ImageCarousel'
import Spinner from '../shared/Spinner'
import { H1, H2 } from '../shared/text'
import { media } from '../../styles/util'

import StoreContext from '../../context/StoreContext'

import HeaderDesktop from '../../assets/img/text-headers/product-collection-header.svg'
import HeaderMobile from '../../assets/img/text-headers/product-collection-header-mobile.svg'

const Main = styled.main`
  position: relative;

  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  min-height: 300vh;
  height: 100%;
  display: grid;
  place-items: center;
`

const wiggle = keyframes`
  0% { transform: rotate(0deg); }
  30% { transform: rotate(2deg) scale(1.1); }
  70% { transform: rotate(-2deg) scale(1.1); }
  100% { transform: rotate(0deg); }
`

const LogoWrapper = styled(Link)`
  &:hover {
    animation: ${wiggle} 250ms;
  }

  position: absolute;
  z-index: 30;

  ${media.mobile} {
    top: 75px;
    left: 21px;
    width: 62px;
    height: 62px;
  }
  ${media.tablet} {
    top: 65px;
    left: 80px;
    width: 144px;
    height: auto;
  }
  ${media.desktop} {
    top: 45px;
    left: 80px;
  }
`

const GradientTop = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(205.94deg, #dacce3 8.91%, #ffffff 43.31%);
  z-index: -1;

  height: 506px;
`

const GradientMiddle = styled(GradientTop)`
  height: 465px;
  background: linear-gradient(
    180deg,
    rgba(250, 102, 102, 0) 19.76%,
    rgba(250, 102, 102, 0.19) 40.32%,
    rgba(164, 87, 138, 0.13) 54.55%,
    rgba(164, 87, 138, 0) 81.33%
  );

  ${media.mobile} {
    top: 33%;
  }
  ${media.tablet} {
    top: 44%;
  }
  ${media.desktop} {
    top: 40%;
  }
`

const GradientBottom = styled(GradientMiddle)`
  ${media.mobile} {
    top: 79.5%;
  }
  ${media.tablet} {
    top: 73%;
  }
  ${media.desktop} {
    top: 70%;
  }
`

const Header = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  text-align: center;

  ${media.mobile} {
    padding: 170px 0 0 0;
    & h1 {
      max-width: 223px;
    }
  }
  ${media.tablet} {
    padding: 275px 80px 0 80px;

    & h1 {
      max-width: 650px;
    }
  }
  ${media.desktop} {
    padding: 280px 80px 0 80px;

    & h1 {
      max-width: 875px;
    }
  }
`

const TopBlobImg = styled.div`
  visibility: hidden;
  height: auto;
  max-width: 800px;
  position: absolute;

  ${media.mobile} {
    width: 75vw;
    top: -120px;
    left: 10%;
  }
  ${media.tablet} {
    transform: translate(-50%, -50%);
    top: -50px;
    left: 50%;
  }
`

const LeftBlobImg = styled.div`
  visibility: hidden;
  height: auto;
  position: absolute;
  transform: translate(-50%, 0);
  ${media.mobile} {
    width: 43%;
    max-width: 230px;
    top: 4%;
    left: 0;
  }
  ${media.tablet} {
    max-width: 250px;
    top: 8%;
  }
  ${media.desktop} {
    width: 32%;
    max-width: 450px;
    top: 4.5%;
    left: 0;
  }
`

const TealBlobImg = styled.div`
  visibility: hidden;
  height: auto;
  position: absolute;
  transform: translate(-50%, 0);

  ${media.mobile} {
    width: 65px;
    top: 100px;
    right: 10px;
  }
  ${media.tablet} {
    top: 8%;
  }
  ${media.desktop} {
    width: 94px;
    top: 150px;
    right: 8%;
  }
`

const PurpleBlobImg = styled.div`
  visibility: hidden;
  height: auto;
  position: absolute;
  transform: translate(-50%, 0);

  ${media.mobile} {
    display: none;
  }
  ${media.desktop} {
    display: block;
    width: 61px;
    top: 15%;
    left: 11%;
  }
`

const PurpleGradientBlobImg = styled.div`
  visibility: hidden;
  height: auto;
  position: absolute;
  transform: translate(-50%, 0);

  ${media.mobile} {
    display: block;
    width: 36px;
    top: 9.5%;
    left: 10%;
  }
  ${media.tablet} {
    top: 15%;
    left: 5%;
  }
  ${media.desktop} {
    display: block;
    width: 61px;
    top: 34%;
    left: 15%;
  }
`

const HeaderImgDesktop = styled.img`
  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
    max-width: 600px;
  }
  ${media.desktop} {
    max-width: none;
  }
`

const HeaderImgMobile = styled.img`
  margin: 0 auto;
  ${media.tablet} {
    display: none;
  }
`

const ImageWrapper = styled.div`
  background-color: ${props => props.theme.colors.grey};
  box-shadow: 16px 16px 0px ${props => props.theme.colors.purple};
  overflow: hidden;
  display: grid;
  place-items: center;
  height: auto;

  ${media.mobile} {
    width: calc(100% - 16px);
    border-radius: 42px;
  }
  ${media.tablet} {
    width: calc(100% - 30px);
    border-radius: 32px;
  }
`

const OrangeBlobImg = styled.div`
  visibility: hidden;
  height: auto;
  position: absolute;
  transform: translate(-50%, 0);

  ${media.mobile} {
    width: 400px;
    bottom: 17%;
    left: -25%;
  }
  ${media.tablet} {
    width: 400px;
    bottom: 24%;
    left: -10%;
  }
  ${media.desktop} {
    width: 700px;
    bottom: 22%;
    left: -10%;
  }
`

const LargeGreenBlobImg = styled.div`
  visibility: hidden;
  height: auto;
  position: absolute;
  transform: translate(50%, 0);

  ${media.mobile} {
    width: 450px;
    top: 41%;
    right: -15%;
  }
  ${media.tablet} {
    top: 46%;
    right: -2%;
  }
  ${media.desktop} {
    width: 750px;
    top: 38%;
    right: -5%;
  }
`

const LargePurpleBlobImg = styled.div`
  visibility: hidden;
  height: auto;
  position: absolute;
  transform: translate(50%, 0);

  ${media.mobile} {
    width: 350px;
    top: 72%;
    right: -20%;
  }
  ${media.desktop} {
    width: 350px;
    top: 62%;
    right: 5%;
  }
`

const GreenSmallBlobImg = styled.div`
  visibility: hidden;
  height: auto;
  position: absolute;

  ${media.mobile} {
    width: 36px;
    bottom: 32%;
    left: 10%;
  }
  ${media.tablet} {
    bottom: 35%;
    left: 5%;
  }
  ${media.desktop} {
    width: 61px;
    bottom: 36%;
    left: 8%;
  }
`

const GreenSmallTopBlob = styled(GreenSmallBlobImg)`
  ${media.mobile} {
    display: none;
  }
  ${media.desktop} {
    display: block;
    width: 61px;
    bottom: auto;
    left: auto;
    top: 24%;
    right: 12%;
  }
`

const PurpleSmallBlobImg = styled.div`
  visibility: hidden;
  height: auto;
  position: absolute;

  ${media.mobile} {
    width: 40px;
    bottom: 50%;
    right: 10%;
  }
  ${media.tablet} {
    bottom: 40%;
    right: 2%;
  }
  ${media.desktop} {
    width: 61px;
    bottom: 42%;
    right: 2%;
  }
`

const GradientPillWrapper = styled.div`
  height: fit-content;
  width: fit-content;
  background: linear-gradient(90deg, #fa6666 1.9%, #f090c3 28.98%, #52d9bf 64.74%, #21a0f8 100%);
  display: grid;
  place-items: center;
  padding: 3px;
  border-radius: 16px;

  ${media.mobile} {
    margin-top: 30px;
  }
  ${media.tablet} {
    margin-top: 42px;

    &:nth-of-type(2) {
      margin-bottom: 90px;
    }
  }
`

const Pill = styled.div`
  background-color: white;
  width: fit-content;
  width: fit-content;
  display: grid;
  place-items: center;
  border-radius: 14px;
  padding: 13px 9px;

  ${media.mobile} {
    & h2 {
      font-size: 25px;
      line-height: 1;
    }
  }
  ${media.tablet} {
    & h2 {
      font-size: 30px;
      line-height: 1;
    }
  }
`

const DesktopOnly = styled.div`
  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
    width: 100%;
  }
`

const MobileOnly = styled.div`
  width: 100%;
  ${media.tablet} {
    display: none;
  }
`

const query = graphql`
  query {
    topLeftBlob: file(relativePath: { regex: "/library/left-blob.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    topBlob: file(relativePath: { regex: "/library/top-blob.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    tealBlob: file(relativePath: { regex: "/library/teal-blob.png/" }) {
      childImageSharp {
        fluid(maxWidth: 75) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    purpleBlob: file(relativePath: { regex: "/library/purple-blob.png/" }) {
      childImageSharp {
        fluid(maxWidth: 75) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    carouselImage1: file(relativePath: { regex: "/library/carousel/image-1.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    carouselImage2: file(relativePath: { regex: "/library/carousel/image-2.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    carouselImage1mobile: file(relativePath: { regex: "/library/carousel/image-1-mobile.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    carouselImage2mobile: file(relativePath: { regex: "/library/carousel/image-2-mobile.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    logo: file(relativePath: { regex: "/logo-purple.png/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    orangeBlob: file(relativePath: { regex: "/library/orange-blob.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    greenBlob: file(relativePath: { regex: "/library/green-blob.png/" }) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    largeGreenBlob: file(relativePath: { regex: "/library/green-blob-large.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    largePurpleBlob: file(relativePath: { regex: "/library/purple-blob-large.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    purpleGradientBlob: file(relativePath: { regex: "/library/purple-gradient-blob.png/" }) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

interface GatsbyImgFluidInterface {
  src: string
  srcSet: string
  sizes: string
  aspectRatio: number
}

const GatsbyImage = ({ fluid }: { fluid: GatsbyImgFluidInterface }) => (
  <Img
    fluid={fluid}
    aria-hidden
    style={{
      height: '100%',
      width: '100%',
    }}
    imgStyle={{
      objectFit: 'contain',
    }}
  />
)

export default () => {
  const {
    logo,
    topLeftBlob,
    topBlob,
    carouselImage1,
    carouselImage2,
    carouselImage1mobile,
    carouselImage2mobile,
    tealBlob,
    purpleBlob,
    orangeBlob,
    greenBlob,
    largeGreenBlob,
    largePurpleBlob,
    purpleGradientBlob,
  } = useStaticQuery(query)

  const { loading, lookupProducts } = useContext(StoreContext)
  const [products, setProducts] = useState([])
  const [bundles, setBundles] = useState([])

  useLockBodyScroll(loading)

  useEffect(() => {
    gsap.to('.collections__blob', { autoAlpha: 1 })
    gsap.to('.collections__blob--small', {
      yoyo: true,
      repeat: -1,
      y: 'random([10, -10])',
      duration: 'random([4, 5])',
    })
    gsap.to('.collections__blob--large', {
      yoyo: true,
      repeat: -1,
      y: 'random([10, -10])',
      duration: 'random([6, 7, 8])',
    })

    if (!loading) {
      const productInfo = lookupProducts(['ASHWAGANDHA', 'ACV', 'TURMERIC', 'ELDERBERRY'])
      const bundleInfo = lookupProducts(['FITBUNDLE', 'ADAPTOGENBUNDLE'])
      setProducts(productInfo)
      setBundles(bundleInfo)
    }
  }, [loading])

  const carouselImages = [
    {
      desktop: carouselImage1.childImageSharp.fluid,
      mobile: carouselImage1mobile.childImageSharp.fluid,
      alt: 'Grummies bottles on side',
    },
    {
      desktop: carouselImage2.childImageSharp.fluid,
      mobile: carouselImage2mobile.childImageSharp.fluid,
      alt: 'Model holding ashwagandha gummies',
    },
  ]

  return (
    <Main>
      <LogoWrapper to="/">
        <GatsbyImage fluid={logo.childImageSharp.fluid} />
      </LogoWrapper>
      <GradientTop />
      <GradientMiddle />
      <LeftBlobImg className="collections__blob collections__blob--large">
        <GatsbyImage fluid={topLeftBlob.childImageSharp.fluid} />
      </LeftBlobImg>
      <TopBlobImg className="collections__blob collections__blob--large">
        <GatsbyImage fluid={topBlob.childImageSharp.fluid} />
      </TopBlobImg>
      <TealBlobImg className="collections__blob collections__blob--small">
        <GatsbyImage fluid={tealBlob.childImageSharp.fluid} />
      </TealBlobImg>
      <GreenSmallTopBlob className="collections__blob collections__blob--small">
        <GatsbyImage fluid={greenBlob.childImageSharp.fluid} />
      </GreenSmallTopBlob>
      <PurpleBlobImg className="collections__blob collections__blob--small">
        <GatsbyImage fluid={purpleBlob.childImageSharp.fluid} />
      </PurpleBlobImg>

      {loading ? (
        <Spinner color={props => props.theme.colors.purple} overlay="#fff" />
      ) : (
        <>
          <Header>
            <H1>Ancient superfoods that taste like modern candy</H1>
            <GradientPillWrapper>
              <Pill>
                <H2>Single Products</H2>
              </Pill>
            </GradientPillWrapper>
          </Header>

          <ProductGrid products={Object.values(products)} isBundles={false} />

          <HeaderImgDesktop src={HeaderDesktop} alt="Trick you into being healthy" />
          <HeaderImgMobile src={HeaderMobile} alt="Trick you into being healthy" />

          <GradientPillWrapper>
            <Pill>
              <H2>Bundles</H2>
            </Pill>
          </GradientPillWrapper>

          <OrangeBlobImg className="collections__blob collections__blob--large">
            <GatsbyImage fluid={orangeBlob.childImageSharp.fluid} />
          </OrangeBlobImg>
          <GreenSmallBlobImg className="collections__blob collections__blob--small">
            <GatsbyImage fluid={greenBlob.childImageSharp.fluid} />
          </GreenSmallBlobImg>

          <LargeGreenBlobImg className="collections__blob collections__blob--large">
            <GatsbyImage fluid={largeGreenBlob.childImageSharp.fluid} />
          </LargeGreenBlobImg>
          <LargePurpleBlobImg className="collections__blob collections__blob--large">
            <GatsbyImage fluid={largePurpleBlob.childImageSharp.fluid} />
          </LargePurpleBlobImg>
          <PurpleSmallBlobImg className="collections__blob collections__blob--small">
            <GatsbyImage fluid={purpleBlob.childImageSharp.fluid} />
          </PurpleSmallBlobImg>
          <PurpleGradientBlobImg className="collections__blob collections__blob--small">
            <GatsbyImage fluid={purpleGradientBlob.childImageSharp.fluid} />
          </PurpleGradientBlobImg>

          <ProductGrid products={Object.values(bundles)} isBundles />

          <GradientBottom />

          <DesktopOnly>
            <ImageCarousel>
              {carouselImages.map(image => (
                <ImageWrapper key={image.alt}>
                  <Img
                    fluid={image.desktop}
                    alt={image.alt}
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              ))}
            </ImageCarousel>
          </DesktopOnly>
          <MobileOnly>
            <ImageCarousel>
              {carouselImages.map(image => (
                <ImageWrapper key={image.alt}>
                  <Img
                    fluid={image.mobile}
                    alt={image.alt}
                    style={{ height: '100%', width: '100%' }}
                  />
                </ImageWrapper>
              ))}
            </ImageCarousel>
          </MobileOnly>
        </>
      )}
    </Main>
  )
}
